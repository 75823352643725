import {
   HTMLAttributes,
   PropsWithChildren,
   // createContext,
   // useContext,
} from "react";
import { cn } from "@/lib/utils";
import styles from "./PapooButton.module.scss";

/*
████████ ██    ██ ██████  ███████ ███████
   ██     ██  ██  ██   ██ ██      ██
   ██      ████   ██████  █████   ███████
   ██       ██    ██      ██           ██
   ██       ██    ██      ███████ ███████
*/

export type TPapooButtonStyle =
   | "default"
   | "secondary"
   | "ghost"
   | "error"
   | "success"
   | "highlight";

export type TPapooButtonSize = "normal" | "small" | "large";

/*
 ██████  ██████  ███    ██ ████████ ███████ ██   ██ ████████
██      ██    ██ ████   ██    ██    ██       ██ ██     ██
██      ██    ██ ██ ██  ██    ██    █████     ███      ██
██      ██    ██ ██  ██ ██    ██    ██       ██ ██     ██
 ██████  ██████  ██   ████    ██    ███████ ██   ██    ██
*/

// Ajouter des états et de fonctions de changement d'état
// type TContext = {
//    xxx?: string;
//    yyy?: string;
// };

// const theContext = createContext<TContext | null>(null);

/*
██████   ██████   ██████  ████████
██   ██ ██    ██ ██    ██    ██
██████  ██    ██ ██    ██    ██
██   ██ ██    ██ ██    ██    ██
██   ██  ██████   ██████     ██
*/

export interface PapooButtonProps
   extends PropsWithChildren<HTMLAttributes<HTMLButtonElement>> {
   variant?: TPapooButtonStyle;
   iconLeft?: JSX.Element;
   iconRight?: JSX.Element;
   shadow?: boolean;
   noBold?: boolean;
   disabled?: boolean;
   size?: TPapooButtonSize;
}

const PapooButton = ({
   className,
   children,
   variant,
   shadow,
   size,
   noBold,
   iconLeft,
   iconRight,
   disabled,
   ...props
}: PapooButtonProps) => {
   return (
      // <theContext.Provider
      //    value={{
      //       xxx, yyy
      //    }}
      // >
      <button
         className={cn(
            "border-papoo-tan-200 bg-papoo-tan-50 flex select-none items-center gap-3 overflow-hidden text-nowrap rounded-lg border-[1px] px-5 py-2 font-bold",
            size === "small"
               ? "px-3 py-1 text-[80%]"
               : size === "large"
                 ? "text-[150%]"
                 : "",
            noBold ? "font-normal" : "",
            shadow ? "shadow-md" : "",
            variant === "highlight"
               ? "border-papoo-tan-500 bg-papoo-tan-200"
               : "",
            variant === "error" ? "bg-error border-black/20 text-white" : "",
            variant === "success"
               ? "bg-success border-black/20 text-white"
               : "",
            variant === "secondary" ? "bg-white" : "",
            variant === "ghost" ? "border-0 bg-transparent" : "",
            disabled
               ? "cursor-default bg-white text-gray-300"
               : "cursor-pointer hover:brightness-90",
            styles.papoo_button,
            className,
         )}
         {...props}
      >
         {iconLeft}
         {children}
         {iconRight}
      </button>
      // </theContext.Provider>
   );
};

PapooButton.displayName = "PapooButton";

export { PapooButton };
