import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { v4 as uuidv4 } from "uuid";

// interface Hash {
//    salt: string;
//    passwordHash: string;
// }

// const sha512Hash = (password: string, salt: string): Hash => {
//    const hash = sha512.hmac.create(salt);
//    hash.update(password);
//    const value = hash.hex();

//    return {
//       salt: salt,
//       passwordHash: value,
//    };
// };

/** +++___+++ 1 **/
type t_state = {
   udid: string;
   current_lang: string;
   default_lang: string;
};

type t_set = (fn: (state: t_state) => t_state) => void;

// Compile in production environment only
// https://github.com/KeJunMao/vite-plugin-conditional-compile
// #v-ifdef VITE_MODE=prod
// const _apiEndpoint = "http://localhost:3333/api/v1";
/** +++___+++ 2 **/

const initialState: t_state = {
   udid: uuidv4(),
   current_lang: "fr",
   default_lang: "fr",
};

const prefStore = (set: t_set) => ({
   ...initialState,
   fix: (val: t_state) =>
      set((): t_state => {
         // console.log(">>>", val, { ...val });
         return {
            ...val,
         } as t_state;
      }),

   initialState: () => {
      // Pour réinitialiser l'état par défaut
      return {
         ...initialState,
      };
   },
   reset: () =>
      // Pour réinitialiser l'état par défaut
      set(
         (state: t_state): t_state => ({
            ...initialState,
            udid: state.udid ?? uuidv4(), // On conserve si possible le UDID
         }),
      ),
   setCurrentLang: (val: string) =>
      set(
         (): t_state =>
            ({
               current_lang: val,
            }) as t_state,
      ),
});

const usePrefStore = create(devtools(persist(prefStore, { name: "prefs" })));

// console.log("usePrefStore", prefStore);

export default usePrefStore;
