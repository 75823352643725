// import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./translations/i18n";
import App from "./App.tsx";
import "./index.css";
import "./scss/vars.scss";
import "./scss/index.scss";
import "./scss/common.scss";

ReactDOM.createRoot(document.getElementById("root")!).render(
   // <React.StrictMode>
   <BrowserRouter>
      <App />
   </BrowserRouter>,
   // </React.StrictMode>,
);
