import { Fragment, useEffect, useState } from "react";
import styles from "./Init.module.scss";
// import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Html5Qrcode, CameraDevice } from "html5-qrcode";
import useSound from "use-sound"; // Si pb pour trouver TS types, voir l'astuce dans dossier `generated-types` de ce projet
import blip_snd from "@/assets/sounds/blip.mp3";
import error_snd from "@/assets/sounds/error.mp3";
import img_background from "../assets/png/background.webp";
import { Img } from "react-image";
import {
   Select,
   SelectContent,
   SelectItem,
   SelectTrigger,
   SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { PapooButton } from "@/components/papoo/PapooButton";
import { useNavigate } from "react-router-dom";
import { getProfile, updateProfile } from "@/stores/db";
import { TProfile } from "@/stores/types";

function Init() {
   const [cams, setCams] = useState<CameraDevice[]>([]);
   const [active_cam, setActiveCam] = useState<CameraDevice>();
   const [play_blip] = useSound(blip_snd);
   const [play_error] = useSound(error_snd);
   const [is_scanner_visible, setIsScannerVisible] = useState<boolean>(false);
   const [, setHtml5Qrcode] = useState<Html5Qrcode>();
   const [name, setName] = useState<string>();
   const [surname, setSurname] = useState<string>();
   const [code, setCode] = useState<string>();
   const [scan_ok, setScanOK] = useState<boolean>();
   const [scan_error, setScanError] = useState<boolean>();
   const [, setClearErrorTimer] = useState<number>();
   const navigate = useNavigate();
   const [, setProfile] = useState<TProfile | null>(null);

   useEffect(() => {
      // This method will trigger user permissions
      Html5Qrcode.getCameras()
         .then((devices) => {
            setCams(devices);
            console.log(devices);
         })
         .catch((err) => {
            // handle err
            console.error(err);
         });
   }, []);

   function handleCameraSelection(id: string) {
      const selectedCam = cams.find((a_cam) => a_cam.id === id);
      setActiveCam(selectedCam);
      setIsScannerVisible(true);
      updateProfile({ selected_camera_id: id });
   }

   useEffect(() => {
      const loadProfile = async () => {
         const profiles = await getProfile();
         if (profiles.length > 0) {
            setProfile(profiles[0]);
            // If there's a saved camera, set it as active
            if (profiles[0].selected_camera_id) {
               setActiveCam(
                  cams.find((cam) => cam.id === profiles[0].selected_camera_id),
               );
            }
         }
      };
      loadProfile();
   }, [cams]);

   function showError() {
      setScanOK(undefined);
      setScanError(true);
      setClearErrorTimer((cur) => {
         console.log("CUR", cur);

         if (cur !== undefined) {
            clearTimeout(cur);
         }
         const new_timer = setTimeout(() => {
            console.log("TIME");

            setScanError(undefined);
         }, 150);
         console.log("NEW", new_timer);
         return new_timer;
      });
   }

   function showOK() {
      setScanOK(true);
      setScanError(undefined);
      setClearErrorTimer((cur) => {
         if (cur !== undefined) {
            clearTimeout(cur);
         }
         return undefined;
      });
   }

   useEffect(() => {
      if (active_cam && is_scanner_visible) {
         const the_reader = new Html5Qrcode("reader");
         setHtml5Qrcode(the_reader);

         if (the_reader) {
            the_reader
               .start(
                  // devices[0].id,
                  active_cam.id,
                  {
                     fps: 5, // Optional, frame per seconds for qr code scanning
                     // qrbox: { width: 350, height: 150 }, // Optional, if you want bounded box UI
                  },
                  (decodedText, decodedResult) => {
                     // do something when code is read
                     console.log(decodedText, decodedResult);
                     // const found = scanned_articles.find(
                     //    (a_scan) => a_scan.code === decodedText,
                     // );
                     const parts = decodedText.match(
                        /^@@([A-Za-z \-']+)_([A-Za-z \-']+)\/([0-9]+)@@/,
                     );
                     if (parts) {
                        showOK();
                        setName(parts[1]);
                        setSurname(parts[2]);
                        setCode(parts[3]);
                        // setCurCode(decodedText);
                        play_blip();
                        // setGotNewScan(true);
                        // setGotExistingScan(false);
                        // setTimeout(() => setGotNewScan(false), 100);
                        // showKeypad(true);
                        // setQty(0);
                        setIsScannerVisible(false);
                     } else {
                        play_error();
                        // setGotExistingScan(true);
                        // setGotNewScan(false);
                        // setTimeout(() => setGotExistingScan(false), 100);
                        // setIsScannerVisible(false);
                        showError();
                     }
                  },
                  (/*errorMessage*/) => {
                     // TODO : en principe ce sont les messages "pas de QRcode scanné", donc pas besoin de logguer...
                     // parse error, ignore it.
                     // console.error(errorMessage);
                     // setVal("");
                  },
               )
               .catch((err) => {
                  // Start failed, handle it.
                  console.error(err);
               });

            return () => {
               console.log("STOP");

               the_reader.stop();
            };
         }
      }
   }, [active_cam, is_scanner_visible, play_blip, play_error]);

   function rescan() {
      setName(undefined);
      setSurname(undefined);
      setCode(undefined);
      setScanOK(undefined);
      setIsScannerVisible(true);
   }

   function validate() {
      updateProfile({
         code: code!,
         name: `${name} ${surname}`,
         selected_camera_id: active_cam?.id,
      });
      navigate("/scanner2");
   }

   return (
      <Fragment>
         <div className="relative h-full w-full overflow-auto">
            <Img
               id="photo-background"
               src={img_background}
               alt="Image login"
               className="fixed h-full w-full rounded-l-lg object-cover opacity-35 saturate-0"
            />

            <div
               className={cn(
                  "relative flex min-h-full w-full flex-col items-center gap-10 pt-10",
                  styles.scanner_presence,
               )}
            >
               <span
                  className={cn(
                     "ml-auto mr-auto flex items-center align-middle",
                     styles.title,
                  )}
               >
                  KIKÉLA
               </span>

               {!is_scanner_visible && (
                  <div className="w-[75%] max-w-[450px] rounded-lg bg-black/60 p-5 text-white">
                     <h2>Initialisation</h2>
                     <ol>
                        <li>Choisissez une caméra</li>
                        <li>Scannez le code reçu à la séance photo</li>
                     </ol>
                  </div>
               )}

               {scan_ok && (
                  <div className="flex w-[75%] max-w-[450px] flex-col rounded-lg bg-black/60 p-5 text-white">
                     <h2>Identifié</h2>
                     <ul>
                        <li>Nom : {name}</li>
                        <li>Prénom : {surname}</li>
                        <li>Code : {code}</li>
                     </ul>
                     <div className="flex gap-5">
                        <Button
                           variant="secondary"
                           onClick={rescan}
                           className="ml-auto mt-3"
                        >
                           Rescanner
                        </Button>
                        <PapooButton
                           variant="success"
                           onClick={validate}
                           className="mt-3"
                        >
                           Valider
                        </PapooButton>
                     </div>
                  </div>
               )}

               {!scan_ok && (
                  <Select
                     // onValueChange={(id) => {
                     //    setActiveCam(cams.find((a_cam) => a_cam.id === id));
                     //    setIsScannerVisible(true);
                     // }}
                     onValueChange={handleCameraSelection}
                     value={active_cam?.id}
                  >
                     <SelectTrigger className="z-[1] w-[300px] ">
                        <SelectValue placeholder="Caméra" />
                     </SelectTrigger>
                     <SelectContent>
                        {cams.map((a_cam) => (
                           <SelectItem id={a_cam.id} value={a_cam.id}>
                              {a_cam.label}
                           </SelectItem>
                        ))}
                     </SelectContent>
                  </Select>
               )}

               {is_scanner_visible && (
                  <div className="relative h-auto w-full items-center justify-center rounded-2xl  border-[1px] border-transparent p-2">
                     <div
                        id="reader"
                        className={cn(
                           "flex h-full w-full items-center justify-center transition-colors duration-100",
                        )}
                        // onClick={(evt) => {
                        //    // alert("OK");
                        //    setIsScannerVisible(false);
                        //    evt.stopPropagation();
                        // }}
                     />
                     <div
                        className={cn(
                           "absolute bottom-0 left-0 right-0 top-0",
                           scan_error === true
                              ? "bg-papoo-red-500/50"
                              : "bg-transparent",
                        )}
                     >
                        {scan_error === false
                           ? "F"
                           : scan_error === true
                             ? "T"
                             : "?"}
                     </div>
                  </div>
               )}
            </div>
         </div>
      </Fragment>
   );
}

export default Init;
