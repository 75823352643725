import Dexie from "dexie";
import { TEtudiant, TProfile } from "./types.ts";

/**
 * https://dexie.org/docs/Version/Version.stores() :
 * - Unlike SQL, you don’t need to specify all properties but only the one you wish to index.
 *   ++     Auto-incremented primary key
 *   &      Unique index
 *   *      Multi-entry index (Means that if key is an array, each array value will be regarded as a key to the object)
 *   [A+B]  Compound index or primary key
 *
 * Only properties of certain types can be indexed. This includes string, number, Date and Array
 * but NOT boolean, null or undefined. Indexing a property path that turns out to hold a non-indexable
 * type will have no effect. And using orderBy() with that property will NOT list that object.
 */
// db.version(1).stores({
//    familles: '&code',     // 1st = Primary key (leave empty for no prim key), others = indexed props
//    articles: '&code',     // 1st = Primary key (leave empty for no prim key), others = indexed props
// })

export class KikelaDB extends Dexie {
   // Declare implicit table properties.
   // (just to inform Typescript. Instantiated by Dexie in stores() method)
   etudiants!: Dexie.Table<TEtudiant, string>; // string = type of the primkey
   profile!: Dexie.Table<TProfile, number>;
   //...other tables goes here...

   constructor() {
      super("kikela");
      this.version(2).stores({
         etudiants: "&code",
         profile: "++id, code, name",
         //...other tables goes here...
      });
   }
}

export const db = new KikelaDB();

export const cleanDB = async () => {
   console.log("CleanDB", db);
   await db.delete({ disableAutoOpen: false });
};

export const getProfile = async () => {
   return await db.profile.toArray();
};

export const updateProfile = async (data: Partial<TProfile>) => {
   const profiles = await getProfile();
   if (profiles.length > 0) {
      await db.profile.update(profiles[0].id!, data);
   } else {
      await db.profile.add(data as TProfile);
   }
};
