// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
// import { faFileImage } from "@fortawesome/pro-solid-svg-icons";
import { db as dexie_db } from "@/stores/db";
// import Dexie, { UpdateSpec } from "dexie";
// import { TEtudiant } from "@/stores/types";

type TJSONEtudiant = {
   civilite: "M." | "Mme";
   // code: string;
   nom: string;
   prenom: string;
   groupe: string; // TP
   email: string;
   etu_id: string;
   code_nip: string;
   code_ine: string;
};

const ImportListe = () => {
   const onDrop = useCallback((acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
         const reader = new FileReader();

         reader.onabort = () => console.log("file reading was aborted");
         reader.onerror = () => console.log("file reading has failed");
         reader.onload = () => {
            // Do whatever you want with the file contents
            const binaryStr = reader.result;
            const str = new TextDecoder().decode(binaryStr as ArrayBuffer);
            console.log(binaryStr, str);
            if (str) {
               const liste: TJSONEtudiant[] = JSON.parse(str);

               for (const an_item of liste) {
                  console.log(an_item.code_ine);

                  dexie_db.etudiants.put(
                     {
                        civilite: an_item.civilite,
                        code: an_item.code_ine,
                        nom: an_item.nom,
                        prenom: an_item.prenom,
                        groupe: an_item.groupe,
                        email: an_item.email,
                        etu_id: an_item.etu_id,
                        code_nip: an_item.code_nip,
                        // code_ine: an_item.code_ine,
                     },
                     an_item.code_ine,
                  );
               }
            }
         };
         reader.readAsArrayBuffer(file);
      });
   }, []);

   const { getRootProps, getInputProps } = useDropzone({ onDrop });

   return (
      <div
         {...getRootProps()}
         className="flex h-full w-full flex-col items-center justify-center"
      >
         <div className="relative flex cursor-pointer flex-col items-center border-4 border-dashed border-papoo-gray-200 px-8 py-[50px]">
            {/* <FontAwesomeIcon
               icon={faFileImage}
               className="border-papoo-gray-200 absolute left-[-30px] top-[-30px] h-[40px] w-[40px] rounded-[50%] border-4 bg-white p-3 drop-shadow-lg"
            /> */}
            faFileImage
            <input {...getInputProps()} />
            <p>Glissez-déposez un fichier</p>
            <p>ou cliquez pour en sélectionner un...</p>
         </div>
      </div>
   );
};

export default ImportListe;
