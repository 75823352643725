import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import frTrans from "./fr";
// import enTrans from "./en";

i18n
   // .use(Backend)
   // .use(LanguageDetector)
   .use(initReactI18next)
   // for all options read: https://www.i18next.com/overview/configuration-options
   .init({
      fallbackLng: "fr",
      debug: false, // passer à false

      interpolation: {
         escapeValue: false, // not needed for react as it escapes by default
      },
      resources: {
         fr: {
            translation: frTrans,
         },
         // en: {
         //    translation: enTrans,
         // },
      },
   });
