import { createContext, useEffect, ReactNode, FC } from "react";
import { registerSW } from "virtual:pwa-register";

export type GlobalContextType = {
   // dummy:string;
};

const theContext = createContext(null);

const GlobalContext = createContext<GlobalContextType | null>(null);

const GlobalContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
   useEffect(() => {
      let timer: number;

      registerSW({
         onRegistered(r) {
            console.log("**SW++ 1 onRegistered", r);
            if (r) {
               r.update(); // Un check immédiat
               timer = setInterval(
                  () => {
                     // console.log('**SW++ 1 update?');
                     r.update();
                  },
                  15 * 60 * 1000,
               ); // Un check toutes les 15min
               console.log("**SW++ 1 setInterval", timer);
            }
         },

         // onNeedRefresh() {
         //    console.log('**SW++ 1 setNeedRefresh');
         //    setNeedRefresh(true)
         // },
         onOfflineReady() {
            console.log("**SW++ 1 onOfflineReady");
         },
      });

      return () => {
         console.log("**SW++ 1 clearInterval", timer);
         clearInterval(timer);
      };
   }, []);

   return <theContext.Provider value={null}>{children}</theContext.Provider>;
};

export { GlobalContext, GlobalContextProvider };
