import { Fragment, useEffect } from "react";
import img_background from "../assets/png/background.webp";
import { Img } from "react-image";
// import logo from "../assets/png/logo.png";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { registerSW } from "virtual:pwa-register";
import useHelpers from "../Hooks/useHelpers";
import usePrefStore from "@/stores/prefs";

function Main() {
   const { getVersion, getVersionDate, getCopyright, getDevRefShort } =
      useHelpers();
   const { udid } = usePrefStore();
   const navigate = useNavigate();
   // const { apiLogout } = useApi();
   // const { is_logged, is_connected, is_fetching, doLogout } = useContext(
   //    LiveUpdateContext,
   // ) as LiveUpdateContextType;

   useEffect(() => {
      const updateSW = registerSW({
         onRegistered(r) {
            console.log("**SW++ 2 onRegistered", r);
            if (r) {
               r.update(); // On check immédiatement à l'affichage du login
            }
         },
         onNeedRefresh() {
            updateSW();
         },
      });
   }, []);

   return (
      <Fragment>
         <div className="relative flex h-full w-full flex-col items-center gap-10">
            <Img
               id="photo-background"
               src={img_background}
               alt="Image login"
               className="absolute h-full w-full rounded-l-lg object-cover opacity-35 saturate-0"
            />
            <h1 className="pt-[50px]">KIKÉLA</h1>
            {/* <Img
               src={logo}
               alt="Logo"
               className="z-10 mx-0 w-1/5 self-center pb-14 pt-10"
            /> */}
            <div className="flex gap-5">
               <Button
                  variant="default"
                  onClick={() => navigate("/import-liste")}
                  className="z-10 cursor-pointer"
               >
                  Import liste
               </Button>
               <Button
                  variant="default"
                  onClick={() => navigate("/scanner")}
                  className="z-10 cursor-pointer"
               >
                  Scanner v{getVersion()}
               </Button>
               <Button
                  variant="default"
                  onClick={() => navigate("/scanner2")}
                  className="z-10 cursor-pointer"
               >
                  Scanner 2
               </Button>
            </div>
            <div className="absolute bottom-0 right-0 mb-5 mr-5 flex flex-col items-end rounded-lg bg-white/50 p-[20px] text-papoo-gray-700">
               <span className="text-black">
                  UDID : {udid.substring(0, 4)}...{udid.slice(-4)}
               </span>
               <span>
                  {getVersion()} - {getCopyright()} - {getVersionDate()}
               </span>
               <span>{getDevRefShort()}</span>
            </div>
         </div>
      </Fragment>
   );
}

export default Main;
