// import { useEffect, useState } from "react";
import styles from "./Keypad.module.scss";
import { cn } from "@/lib/utils";

function Keypad() {
   return (
      <div className={cn("flex flex-col gap-2", styles.keypad)}>
         <div className={cn(styles.row)}>
            <span>7</span>
            <span>8</span>
            <span>9</span>
         </div>
         <div className={cn(styles.row)}>
            <span>4</span>
            <span>5</span>
            <span>6</span>
         </div>
         <div className={cn(styles.row)}>
            <span>1</span>
            <span>2</span>
            <span>3</span>
         </div>
         <div className={cn(styles.row)}>
            <span>√</span>
            <span>0</span>
            <span>&lt;</span>
         </div>
      </div>
   );
}

export default Keypad;
