import { Routes, Route } from "react-router-dom";
import Main from "@/pages/Main";
import Init from "@/pages/Init";
import Scanner from "@/pages/Scanner";
import ImportListe from "@/pages/ImportListe";
import "./App.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSpinnerScale } from "@fortawesome/pro-solid-svg-icons";
// import "moment/dist/locale/fr";
import { GlobalContextProvider } from "@/Contexts/GlobalContext.js";
import ScanPresence from "./pages/ScanPresence";
import { useEffect, useState } from "react";
import { getProfile } from "@/stores/db";

function App() {
   const [hasProfile, setHasProfile] = useState(false);

   useEffect(() => {
      const checkProfile = async () => {
         const profile = await getProfile();
         setHasProfile(profile.length > 0);
      };
      checkProfile();
   }, []);

   return (
      <GlobalContextProvider>
         <div className="flex flex-grow justify-center bg-amber-900/50">
            <Routes>
               {/* <Route path="/" element={<Init />} /> */}
               <Route
                  path="/"
                  element={hasProfile ? <ScanPresence /> : <Init />}
               />{" "}
               // ICI
               <Route path="/scan" element={<Main />} />
               <Route path="/import-liste" element={<ImportListe />} />
               <Route path="/scanner" element={<Scanner />} />
               <Route path="/scanner2" element={<ScanPresence />} />
            </Routes>
         </div>
      </GlobalContextProvider>
   );
}

export default App;
