import { Button } from "@/components/ui/button";
import { Html5Qrcode, CameraDevice } from "html5-qrcode";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Scanner.module.scss";
import useHelpers from "@/Hooks/useHelpers";
import useSound from "use-sound"; // Si pb pour trouver TS types, voir l'astuce dans dossier `generated-types` de ce projet
import blip_snd from "@/assets/sounds/blip.mp3";
import error_snd from "@/assets/sounds/error.mp3";
import key_snd from "@/assets/sounds/key.mp3";
import {
   Select,
   SelectContent,
   SelectItem,
   SelectTrigger,
   SelectValue,
} from "@/components/ui/select";
// import {
//    faDeleteLeft,
//    faCheck,
//    faBarcodeRead,
// } from "@fortawesome/pro-solid-svg-icons";

// import filesaver from "file-saver";
import moment from "moment";
import fileDownload from "js-file-download";
import { cn } from "@/lib/utils";

type TArticle = {
   code: string;
   qty: number;
};

function Scanner() {
   const { formatNumber } = useHelpers();
   const navigate = useNavigate();
   // const [val, setVal] = useState<string>();
   // const [count, setCount] = useState<number>(0);
   const [got_new_scan, setGotNewScan] = useState<boolean>(false);
   const [got_existing_scan, setGotExistingScan] = useState<boolean>(false);
   const [scanned_articles, setScannedArticles] = useState<TArticle[]>([]);
   const [cams, setCams] = useState<CameraDevice[]>([]);
   const [active_cam, setActiveCam] = useState<CameraDevice>();
   const [is_scanner_visible, setIsScannerVisible] = useState<boolean>(false);
   const [is_keypad_visible, setIsKeypadVisible] = useState<boolean>(false);
   const [cur_code, setCurCode] = useState<string>();
   const [qty, setQty] = useState<number>(0);
   const [, setUsedPreValue] = useState<boolean>(false); // Si on a utilisé un bouton "pré-rempli"
   const [play_blip] = useSound(blip_snd);
   const [play_error] = useSound(error_snd);
   const [play_key] = useSound(key_snd);

   useEffect(() => {
      // This method will trigger user permissions
      Html5Qrcode.getCameras()
         .then((devices) => {
            setCams(devices);
            console.log(devices);
         })
         .catch((err) => {
            // handle err
            console.error(err);
         });
   }, []);

   const showScanner = useCallback((show: boolean) => {
      setIsKeypadVisible(false); // Montrer ou masquer le scanner ne provoque pas d'aff du keypad. Utiliser showKeypad() à la place
      setIsScannerVisible(show);
   }, []);

   const showKeypad = useCallback((visible: boolean) => {
      setIsKeypadVisible(visible);
      setIsScannerVisible(false);
   }, []);

   useEffect(() => {
      if (active_cam && is_scanner_visible) {
         const html5QrCode = new Html5Qrcode("reader");

         html5QrCode
            .start(
               // devices[0].id,
               active_cam.id,
               {
                  fps: 5, // Optional, frame per seconds for qr code scanning
                  qrbox: { width: 150, height: 150 }, // Optional, if you want bounded box UI
               },
               (decodedText, decodedResult) => {
                  // do something when code is read
                  console.log(decodedText, decodedResult);
                  const found = scanned_articles.find(
                     (a_scan) => a_scan.code === decodedText,
                  );
                  if (!found) {
                     setCurCode(decodedText);
                     // setPrevCode((cur) => cur + "," + decodedText);
                     // setScannedArticles((cur) => [
                     //    ...cur,
                     //    { code: decodedText, qty: 0 },
                     // ]);
                     play_blip();
                     setGotNewScan(true);
                     setGotExistingScan(false);
                     setTimeout(() => setGotNewScan(false), 100);
                     showKeypad(true);
                     setQty(0);
                  } else {
                     play_error();
                     setGotExistingScan(true);
                     setGotNewScan(false);
                     setTimeout(() => setGotExistingScan(false), 100);
                  }
               },
               (/*errorMessage*/) => {
                  // TODO : en principe ce sont les messages "pas de QRcode scanné", donc pas besoin de logguer...
                  // parse error, ignore it.
                  // console.error(errorMessage);
                  // setVal("");
               },
            )
            .catch((err) => {
               // Start failed, handle it.
               console.error(err);
            });

         return () => {
            console.log("STOP");

            html5QrCode.stop();
         };
      }
   }, [
      active_cam,
      is_scanner_visible,
      play_blip,
      play_error,
      scanned_articles,
      showKeypad,
   ]);

   function download() {
      // const ret_data = new Blob(
      //    // const ret_data = new File(
      //    [
      //       JSON.stringify(
      //          Array.from(codes).map((a_code) => {
      //             return {
      //                code: a_code,
      //                qty: 123,
      //             };
      //          }),
      //       ),
      //    ],
      //    // "codes.txt",
      //    {
      //       // type: "text/plain",
      //       type: "application/json",
      //    },
      // );
      // filesaver.saveAs(ret_data, "codes.txt");
      // filesaver.saveAs(ret_data);

      fileDownload(
         JSON.stringify(scanned_articles),
         moment().format("DD_MM_YYYY_HH_mm_ss"),
         // "application/json",
      );
   }

   const validateQty = useCallback(() => {
      if (qty > 0) {
         setScannedArticles((cur) => [
            ...cur,
            { code: cur_code, qty } as TArticle,
         ]);
      }
      showScanner(true);
   }, [cur_code, qty, showScanner]);

   const addDigit = useCallback(
      (digit: number) => {
         play_key();
         setUsedPreValue((cur) => {
            if (cur) {
               // Si on a utilisé une valeur pré-remplie (un des boutons pré-remplis). On remplace par le chiffre tappé
               setQty(digit);
            } else {
               // Sinon on fonctionne comme une saisie de calculatrice
               setQty((cur) => cur * 10 + digit);
            }
            return false;
         });
      },
      [play_key],
   );

   const fillWithPreValue = useCallback(
      (new_qty: number) => {
         play_key();
         setQty(new_qty);
         setUsedPreValue(true);
      },
      [play_key],
   );

   return (
      <div className="relative flex h-full flex-col items-center justify-center gap-5">
         <div id="reader" className="flex w-full"></div>
         {cur_code}
         {got_new_scan && (
            <div className="absolute z-10 flex h-full w-full bg-green-500/50"></div>
         )}
         {got_existing_scan && (
            <div className="absolute z-10 flex h-full w-full bg-red-500/50"></div>
         )}
         <div>
            {scanned_articles.map((a_scan) => {
               return (
                  <span id={a_scan.code}>
                     {a_scan.qty} x {a_scan.code}
                  </span>
               );
            })}
         </div>
         {!active_cam && (
            <Select
               onValueChange={(id) =>
                  setActiveCam(cams.find((a_cam) => a_cam.id === id))
               }
            >
               <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Caméra" />
               </SelectTrigger>
               <SelectContent>
                  {cams.map((a_cam) => (
                     <SelectItem id={a_cam.id} value={a_cam.id}>
                        {a_cam.label}
                     </SelectItem>
                  ))}
               </SelectContent>
            </Select>
         )}
         {is_keypad_visible && active_cam && (
            <div
               className={cn(
                  "flex max-w-[80%] flex-col gap-[20px]",
                  styles.keypad,
               )}
            >
               <div className="flex gap-5">
                  <Button
                     variant="secondary"
                     onClick={() => {
                        showScanner(true);
                        play_key();
                     }}
                  >
                     faBarcodeRead
                     {/* <FontAwesomeIcon icon={faBarcodeRead} /> */}
                  </Button>

                  <div className="flex w-full items-center justify-end rounded-lg border-4 border-papoo-gray-200 pr-5 text-[30px] font-extrabold">
                     {formatNumber(qty)}
                  </div>
               </div>
               <div className="grid w-full grid-flow-row grid-cols-3 grid-rows-3 gap-[15px]">
                  <Button onClick={() => addDigit(1)}>1</Button>
                  <Button onClick={() => addDigit(2)}>2</Button>
                  <Button onClick={() => addDigit(3)}>3</Button>
                  <Button onClick={() => addDigit(4)}>4</Button>
                  <Button onClick={() => addDigit(5)}>5</Button>
                  <Button onClick={() => addDigit(6)}>6</Button>
                  <Button onClick={() => addDigit(7)}>7</Button>
                  <Button onClick={() => addDigit(8)}>8</Button>
                  <Button onClick={() => addDigit(9)}>9</Button>
                  <Button
                     onClick={() => {
                        setUsedPreValue((used) => {
                           if (used) {
                              setQty(0);
                           } else {
                              setQty((cur) => Math.floor(cur / 10));
                           }
                           return false;
                        });
                        play_key();
                     }}
                     className="bg-papoo-red-500 hover:bg-papoo-red-700"
                  >
                     faDeleteLeft
                     {/* <FontAwesomeIcon icon={faDeleteLeft} /> */}
                  </Button>
                  <Button onClick={() => addDigit(0)}>0</Button>
                  <Button
                     onClick={() => {
                        validateQty();
                        play_key();
                     }}
                     className="bg-papoo-green-500 hover:bg-papoo-green-700"
                  >
                     faCheck
                     {/* <FontAwesomeIcon icon={faCheck} /> */}
                  </Button>
               </div>
               <div className="grid w-full grid-flow-row grid-cols-3 grid-rows-3 gap-[15px] border-t-4 border-papoo-gray-400 pt-[25px]">
                  <Button onClick={() => fillWithPreValue(10)}>10</Button>
                  <Button onClick={() => fillWithPreValue(15)}>15</Button>
                  <Button onClick={() => fillWithPreValue(20)}>20</Button>
                  <Button onClick={() => fillWithPreValue(25)}>25</Button>
                  <Button onClick={() => fillWithPreValue(30)}>30</Button>
                  <Button onClick={() => fillWithPreValue(40)}>40</Button>
                  <Button onClick={() => fillWithPreValue(50)}>50</Button>
                  <Button onClick={() => fillWithPreValue(75)}>75</Button>
                  <Button onClick={() => fillWithPreValue(100)}>100</Button>
               </div>
            </div>
         )}
         <div className="flex gap-5">
            {is_scanner_visible && active_cam && (
               <Button onClick={() => showScanner(false)}>Arrêter</Button>
            )}
            {!is_scanner_visible && active_cam && (
               <Button
                  onClick={() => {
                     showScanner(true);
                  }}
               >
                  Scanner
               </Button>
            )}
            {!is_scanner_visible &&
               scanned_articles.length > 0 &&
               !is_keypad_visible && (
                  <Button onClick={() => download()}>Télécharger</Button>
               )}
            {!is_scanner_visible && (
               <Button variant="destructive" onClick={() => navigate("/")}>
                  Quitter
               </Button>
            )}
         </div>
      </div>
   );
}

export default Scanner;
