import { Fragment, useEffect, useState } from "react";
import img_header_boy from "../assets/png/scan-header-boy.webp";
import img_header_girl from "../assets/png/scan-header-girl.webp";
import qrcode_svg from "../assets/icones/qr_code_scanner.svg";
import styles from "./ScanPresence.module.scss";
import { Img } from "react-image";
// import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import moment from "moment";
import Keypad from "@/components/Keypad";
import { Home } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Html5Qrcode, CameraDevice } from "html5-qrcode";
import useSound from "use-sound"; // Si pb pour trouver TS types, voir l'astuce dans dossier `generated-types` de ce projet
import blip_snd from "@/assets/sounds/blip.mp3";
import error_snd from "@/assets/sounds/error.mp3";
import {
   Select,
   SelectContent,
   SelectItem,
   SelectTrigger,
   SelectValue,
} from "@/components/ui/select";
import { getProfile, updateProfile } from "@/stores/db";

function ScanPresence() {
   const [show_boy, setShowBoy] = useState<boolean>(moment().unix() % 2 === 0);
   const navigate = useNavigate();
   const [cams, setCams] = useState<CameraDevice[]>([]);
   const [active_cam, setActiveCam] = useState<CameraDevice>();
   const [play_blip] = useSound(blip_snd);
   const [play_error] = useSound(error_snd);
   const [is_scanner_visible, setIsScannerVisible] = useState<boolean>(false);

   useEffect(() => {
      const timer = setInterval(() => {
         setShowBoy((cur) => !cur);
      }, 10000);

      return () => clearInterval(timer);
   }, []);

   useEffect(() => {
      // This method will trigger user permissions
      Html5Qrcode.getCameras()
         .then((devices) => {
            setCams(devices);
            console.log(devices);
         })
         .catch((err) => {
            // handle err
            console.error(err);
         });
   }, []);

   useEffect(() => {
      const loadProfile = async () => {
         const profiles = await getProfile();
         if (profiles.length > 0 && profiles[0].selected_camera_id) {
            setActiveCam(
               cams.find((cam) => cam.id === profiles[0].selected_camera_id),
            );
         }
      };
      loadProfile();
   }, [cams]);

   function handleCameraSelection(id: string) {
      setActiveCam(cams.find((a_cam) => a_cam.id === id));
      updateProfile({ selected_camera_id: id });
   }

   useEffect(() => {
      if (active_cam && is_scanner_visible) {
         const html5QrCode = new Html5Qrcode("reader");

         html5QrCode
            .start(
               // devices[0].id,
               active_cam.id,
               {
                  fps: 5, // Optional, frame per seconds for qr code scanning
                  // qrbox: { width: 350, height: 150 }, // Optional, if you want bounded box UI
               },
               (decodedText, decodedResult) => {
                  // do something when code is read
                  console.log(decodedText, decodedResult);
                  // const found = scanned_articles.find(
                  //    (a_scan) => a_scan.code === decodedText,
                  // );
                  const found = true;
                  if (found) {
                     // setCurCode(decodedText);
                     play_blip();
                     // setGotNewScan(true);
                     // setGotExistingScan(false);
                     // setTimeout(() => setGotNewScan(false), 100);
                     // showKeypad(true);
                     // setQty(0);
                     setIsScannerVisible(false);
                  } else {
                     play_error();
                     // setGotExistingScan(true);
                     // setGotNewScan(false);
                     // setTimeout(() => setGotExistingScan(false), 100);
                     setIsScannerVisible(false);
                  }
               },
               (/*errorMessage*/) => {
                  // TODO : en principe ce sont les messages "pas de QRcode scanné", donc pas besoin de logguer...
                  // parse error, ignore it.
                  // console.error(errorMessage);
                  // setVal("");
               },
            )
            .catch((err) => {
               // Start failed, handle it.
               console.error(err);
            });

         return () => {
            console.log("STOP");

            html5QrCode.stop();
         };
      }
   }, [active_cam, is_scanner_visible, play_blip, play_error]);

   return (
      <Fragment>
         <div
            className={cn(
               "relative flex min-h-full w-full flex-col items-center gap-10",
               styles.scanner_presence,
            )}
         >
            <Img
               id="scan-background_boy"
               src={img_header_boy}
               alt="Image bg"
               className={cn(
                  "fixed h-full w-full rounded-l-lg object-cover saturate-0 transition-all duration-1000",
                  show_boy ? "opacity-[7%]" : "opacity-0",
               )}
            />
            <Img
               id="scan-background_girl"
               src={img_header_girl}
               alt="Image bg"
               className={cn(
                  "fixed h-full w-full rounded-l-lg object-cover saturate-0 transition-all duration-1000",
                  show_boy ? "opacity-0" : "opacity-[7%]",
               )}
            />
            <div className="flex min-h-full w-full max-w-[500px] flex-col gap-8 p-4">
               <div className="relative mt-5 flex h-20 w-full items-center gap-3">
                  <span className="absolute left-0 top-[calc(50%-30px)] h-[60px] w-[60px] rounded-3xl border-2 border-amber-900/50 p-2 text-white shadow-md">
                     <SvgIcon
                        component={Home}
                        inheritViewBox
                        style={{
                           width: "100%",
                           height: "100%",
                        }}
                        className="h-full"
                        onClick={() => navigate("/")}
                     />
                  </span>
                  <span
                     className={cn(
                        "ml-auto mr-auto flex items-center align-middle",
                        styles.title,
                     )}
                  >
                     KIKÉLA
                  </span>
               </div>
               <div
                  className="relative w-full rounded-xl"
                  onClick={() => setIsScannerVisible((cur) => !cur)}
               >
                  <Img
                     id="scan-header_boy"
                     src={img_header_boy}
                     alt="Image scan"
                     className={cn(
                        "w-full rounded-2xl border-[1px] border-transparent drop-shadow-md transition-all duration-1000",
                        show_boy && !is_scanner_visible
                           ? "opacity-100"
                           : "opacity-0",
                     )}
                  />
                  {!is_scanner_visible && (
                     <>
                        <Img
                           id="scan-header_girl"
                           src={img_header_girl}
                           alt="Image scan"
                           className={cn(
                              "absolute left-0 top-0 w-full rounded-2xl border-[1px] border-transparent drop-shadow-md transition-all duration-1000",
                              show_boy ? "opacity-0" : "opacity-100",
                           )}
                        />
                        <img
                           src={qrcode_svg}
                           width={96}
                           height={96}
                           className="absolute right-0 top-0 mr-3 mt-3 self-center rounded-2xl bg-white p-3 opacity-50"
                        />
                     </>
                  )}
                  {is_scanner_visible && (
                     <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center rounded-2xl  border-[1px] border-transparent p-2">
                        <div
                           id="reader"
                           className="flex h-full w-full items-center justify-center"
                           onClick={(evt) => {
                              // alert("OK");
                              setIsScannerVisible(false);
                              evt.stopPropagation();
                           }}
                        />
                     </div>
                  )}
               </div>
               <Select
                  // onValueChange={(id) =>
                  //    setActiveCam(cams.find((a_cam) => a_cam.id === id))
                  // }
                  onValueChange={handleCameraSelection}
                  value={active_cam?.id}
               >
                  <SelectTrigger className="w-[180px]">
                     <SelectValue placeholder="Caméra" />
                  </SelectTrigger>
                  <SelectContent>
                     {cams.map((a_cam) => (
                        <SelectItem id={a_cam.id} value={a_cam.id}>
                           {a_cam.label}
                        </SelectItem>
                     ))}
                  </SelectContent>
               </Select>
               <Keypad />
            </div>
         </div>
      </Fragment>
   );
}

export default ScanPresence;
